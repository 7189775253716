import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";
import Link from "gatsby-link"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section3, classes.sectionDarkContact)}>
      <div>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          align="center"
        >
          <Grid item lg={12} xs={10} sm={10} md={12}>
            <h2 align="center" className={classes.title6}>
              Get A Free Quote
            </h2>
          </Grid>
          <Grid item lg={12} xs={10} sm={10} md={10}>
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "white",
                maxWidth: "1000px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Having plumbing issues late at night or in the middle of an event
              or gathering? No problem. Our emergency plumbers can step in and
              help you right away. What you don’t want to do is wait to give us
              a call because it can end up costing you a lot more money and
              frustration in the long run if you put it off.
            </p>
            <br />
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "white",
                maxWidth: "1000px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}

            >
              <Link to="/contact-us" href="https://goproplumbing.ca/contact-us" className={classes.paragraph2} style={{ color: "#0099CC" }} >
                Get in touch with us today{" "}
              </Link>
              to learn more about our services or if you are dealing with water
              leaks and plumbing complications that require an expert.
            </p>
          </Grid>
          <br />
          <Grid item lg={12} xs={10} sm={10} md={12}>
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                marginTop: "10px",
              }}
              variant="contained"
              href="tel:647-370-9868"
            >
              Call Us
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
