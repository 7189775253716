import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div style={{ padding: "0px 0px 80px 0px" }}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              WHY CHOOSE US
            </h2>
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "#212121",
                fontSize: "normal",
                fontFamily: "poppins2",
                maxWidth: "800px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}
            >
              There are a lot of plumbers out there to choose from but only one
              company that can deliver guaranteed results and that will ensure
              you are satisfied with the emergency plumbing services. Not not
              only that but we will take care of the matter at a price you can
              afford.
            </p>
            <br />
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "#212121",
                fontFamily: "poppins2",
                maxWidth: "800px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Our team of plumbers cares about workmanship and making sure we
              not only fix the issue in a timely fashion but that it is a
              long-term fix that will prevent further complications in the
              future. We treat each customer with the utmost respect and fairly
              and will never recommend anything that’s not necessary.
            </p>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div align="center">
            <iframe
              className={classes.image2}
              title="Leak detection system"
              src="https://www.youtube.com/embed/QJ2f5Fvms8s"
              frameBorder="0"
              allow="encrypted-media; gyroscope;"
              allowFullScreen
              align="center"
            />
          </div>
        </Grid>
      </div>
    </div>
  );
}
