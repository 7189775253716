import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";
import Link from "gatsby-link";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Home from "../../assets/emergency-plumber-4.svg";
import Faucet from "../../assets/emergency-plumber-2.svg";
import Drain from "../../assets/emergency-plumber-3.svg";
import Valve from "../../assets/emergency-plumber.svg";

const useStyles = makeStyles({
  ...styles,

  infoText: {
    "@media only screen and (min-width: 1200px) and (max-height: 1600px)": {
      height: "900px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div style={{ padding: "60px 0px 0px 0px" }}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              HOW WE CAN HELP
            </h2>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{ background: "none", color: "#212121" }}
        >
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "#212121",
                maxWidth: "800px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}
            >
              It doesn’t matter the type of plumbing issue you’re dealing with
              at your home. We have the expertise, knowledge, and skill set to
              step in and assist you when you’re in a bind. It can be anything
              from drain cleaning to pipe repair or{" "}
              <Link
                style={{ color: "#0099CC" }}
                href="https://goproplumbing.ca/leak-detection-phyn"
                to="/leak-detection-phyn"
              >
                {" "}
                leak detection
              </Link>
              , and no job or plumbing issue is too big or complicated for our
              technicians.
            </p>
            <br />
            <p
              className={classes.paragraph2}
              style={{
                font: "16px",
                color: "#212121",
                maxWidth: "800px",
                justifyContent: "center",
                margin: "auto",
                textAlign: "left",
              }}
            >
              We will take the time to inspect and dissect the issue and talk
              with you about our plan and solution to fix it before we start any
              work. Our plumbers are professional in all interactions and our
              emergency plumbing services are affordable so you know you’ll be
              in good hands.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <div className={classes.infoText}>
              <Grid
                container
                justify="center"
                direction="column"
                alignItem="center"
                alignContent="center"
              >
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <div className={classes.imageContainer} align="center">
                    <Home alt="toronto-plumbers" height="128px" width="128px" />
                  </div>
                </Grid>
                <Grid item lg={12} md={10} sm={11} xs={11}>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <h3 align="center" className={classes.title2}>
                      Pipe Repair
                    </h3>
                  </Grid>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <p className={classes.paragraph}>
                      Most plumbing malfunctions are linked to cracked or burst
                      pipes. There are many causes for damaged piping, from tree
                      roots looking for extra water supply to improper
                      installation and ice crystals.
                    </p>
                    <p className={classes.paragraph}>
                      Whatever the cause, as an emergency plumber, GoPro
                      Plumbing will quickly find the adequate solutions and
                      restore your piping system. A leaky pipe can cause much
                      more damage than a simple stain on the wall. It’s better
                      to take action now and avoid further damage both to your
                      plumbing system and structure.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <div className={classes.infoText}>
              <Grid
                container
                justify="center"
                direction="column"
                alignItem="center"
                alignContent="center"
              >
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <div className={classes.imageContainer} align="center">
                    <Faucet
                      alt="toronto-plumbers"
                      height="128px"
                      width="128px"
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={10} sm={11} xs={11}>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <h3 align="center" className={classes.title2}>
                      Drain Cleaning
                    </h3>
                  </Grid>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <p className={classes.paragraph}>
                      In plumbing, everything is connected! You might have
                      problems with your bathtub drain for a while but after a
                      few months that minor issue can result in a major flood.
                      Drain cleaning is the perfect way to ensure your piping
                      system is in top condition. By using modern technology and
                      drain snakes, at GoPro Plumbing, we can easily detect the
                      root of the problem, unclog your drains, and prevent
                      future complications.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <div className={classes.infoText}>
              <Grid
                container
                justify="center"
                direction="column"
                alignItem="center"
                alignContent="center"
              >
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <div className={classes.imageContainer} align="center">
                    <Drain
                      alt="toronto-plumbers"
                      height="128px"
                      width="128px"
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={10} sm={11} xs={11}>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <h3 align="center" className={classes.title2}>
                      Leak Detection
                    </h3>
                  </Grid>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <p className={classes.paragraph}>
                      At GoPro Plumbing we use advanced technology to monitor
                      our clients’ piping system and detect leaks or other
                      possible issues. We are not an ordinary emergency plumber
                      as we use PhynPlus to help you keep track of your water
                      spending and piping’s condition. This device uses pressure
                      wave analysis as a way to detect leaks, ice crystal
                      forming, and other types of damage.
                    </p>
                    <p className={classes.paragraph}>
                      It is compatible with PhynApp, Google Assistant, and
                      Amazon Alexa. PhynPlus enables you to have more time for
                      contacting an emergency plumber as it alerts you the
                      second it detects any plumbing-related problems.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <div className={classes.infoText}>
              <Grid
                container
                justify="center"
                direction="column"
                alignItem="center"
                alignContent="center"
              >
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <div className={classes.imageContainer} align="center">
                    <Valve
                      alt="toronto-plumbers"
                      height="128px"
                      width="128px"
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={10} sm={11} xs={11}>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <h3 align="center" className={classes.title2}>
                      Inspection
                    </h3>
                  </Grid>
                  <Grid item lg={8} md={10} sm={11} xs={11}>
                    <p className={classes.paragraph}>
                      Inspections are a necessary part of every plumbing repair.
                      Before the issue is fixed, the emergency plumber must
                      learn the cause for the mess, whether it be a flood or an
                      overflowing toilet. At GoPro Plumbing, we use the latest
                      equipment for conducting thorough inspections within your
                      piping system.
                    </p>
                    <p className={classes.paragraph}>
                      If you call us in the middle of the night, we will be 100%
                      ready, bringing all the necessary equipment to your
                      location. Choose us as your next emergency plumber in the
                      GTA if you want reliable and efficient emergency plumbing.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
