import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";
import Link from "gatsby-link";
import Certified from "../../assets/siren.svg";
import Design from "../../assets/plumber.svg";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "0px 80px 0px 80px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title}>Emergency Plumbing</h2>
          <p className={classes.paragraph2}>
            Having a plumbing issue is annoying no matter the time of day. It
            can become a real problem to deal with when you have water leaks at
            your home in the middle of the night or during non-business hours
            when you may not know where to turn for help.
          </p>
          <p className={classes.paragraph2}>
            The truth is that plumbing problems can’t wait and should be
            addressed right away. Otherwise, you risk even more complications
            and dilemmas. Be glad to know that we are certified and insured{" "}
            <Link
              href="https://goproplumbing.ca/emergency-plumber"
              to="/emergency-plumber"
              className={classes.paragraph2}
              style={{ color: "#0099CC" }}
            >
              emergency plumbers{" "}
            </Link>{" "}
            that can step in and make a fix on a whim.
          </p>
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> Emergency Plumbing Services</h2>
          <p className={classes.paragraph2}>
            If you’re faced with a plumbing issue at the wrong time of day then
            you will need to contact emergency plumbing services. You must get
            the problem looked at now and repaired in a timely manner.
            Otherwise, you risk more damage and that you will likely have to
            invest even more money in the clean-up process.
          </p>
          <p className={classes.paragraph2}>
            Contact <Link
              href="https://goproplumbing.ca/contact-us"
              to="/contact-us"
              className={classes.paragraph2}
              style={{ color: "#0099CC" }}
            >
              GoPro Plumbing {" "}
            </Link>{" "} when you require immediate plumbing services
            and we will be at your door in no time. Our expert team is available
            around the clock and is waiting for your call. You can count on us
            for reliable emergency plumbing services and feel confident that we
            will get the issue fixed quickly and the work completed right the
            first time.
          </p>
        </Grid>
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Design alt="toronto-plumbers" height="100%" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
